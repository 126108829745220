export default {
  // "Before You Start": [
  //   {
  //     "How to": [
  //       {
  //         title: "How to use ZIROTOHERO Effectively",
  //         slug: "how-to-use-zirotohero-effectively",
  //         status: "not started",
  //         cryptedImageName: "76f6cddbce149645d2b57aaa1ffacc1d",
  //       },
  //       {
  //         title: "What Is a Front-End Developer?",
  //         slug: "what-is-a-front-end-developer",
  //         status: "not started",
  //         cryptedImageName: "fadb864727a43e633e9bad0bde0f2562",
  //       },
  //     ],
  //   },
  //   {
  //     Internet: [
  //       {
  //         title: "Basic Knowledge",
  //         slug: "basic-knowledge",
  //         status: "not started",
  //         cryptedImageName: "3959109d463e9a05a21690a0aed0cb9b",
  //       },
  //     ],
  //   },
  // ],
  Languages: [
    {
      HTML: [
        {
          title: "First Steps to HTML",
          slug: "html-first-steps",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=DEylRE9aUt8&list=PLyMSoUmH8WzB7ZMri5pdOFHD9GzVrtRI9",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "33",
        },
        {
          title: "Images",
          slug: "html-images",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=qVCkTp4eQHM&list=PLyMSoUmH8WzC73yradSdWexaY-0RPSC_b",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "8",
        },
        {
          title: "Lists",
          slug: "html-lists",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=4ekprRLj1lg&list=PLyMSoUmH8WzCJHaYR0hF393dLaeCrzoM5",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "6",
        },
        {
          title: "Links",
          slug: "html-links",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=7BkcRSwz_uQ&list=PLyMSoUmH8WzCwz0l07bnW7HLFR2Z4CC3E",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "6",
        },
        {
          title: "Structuring Content",
          slug: "html-structuring-content",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=gxxUQYdwhRE&list=PLyMSoUmH8WzAkZu1wAYKScDRVFZn7iq0B",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "22",
        },
        {
          title: "Table",
          slug: "html-table",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=yh7l8hJjJwg&list=PLyMSoUmH8WzDftY0DNmix3KkCN5z2c0xv",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "11",
        },
        {
          title: "Forms Prerequesites",
          slug: "html-forms-prerequisites",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=Oqsb9idJ8_Q&list=PLyMSoUmH8WzAj7Pb_O0Za5sOgBlJt3Urb",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "13",
        },
        {
          title: "Forms Controls",
          slug: "html-form-controls",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=Ejs7MndFwfg&list=PLyMSoUmH8WzB4QWK1NitCVrV9iTl05VqT",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "40",
        },
        {
          title: "Content Categories",
          slug: "html-content-categories",
          status: "finished",
          cryptedImageName: "b419aa4f60bc38c3aee245443c924162",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=iGp4FAhbRps&list=PLyMSoUmH8WzAJEcnAfW7zAZJOJG4yPUn9",
          targetedDate: "2021-01-29T01:02:03",
          progression: "100%",
          lessonsFinished: "7",
        },
      ],
    },
    {
      CSS: [
        {
          title: "Before you Start",
          slug: "css-before-you-start",
          status: "in progress",
          cryptedImageName: "a1d8daf5de81f61b7c357dd86c5395fe",
          youtubePlayListLink:
            "https://www.youtube.com/watch?v=QfMuD-xpRXM&list=PLyMSoUmH8WzDaQBKdkO7tlOe8SU5_5dSA",
          targetedDate: "2021-05-10T01:02:03",
          progression: "88%",
          lessons: "26",
          lessonsFinished: "23 / 26",
        },
        {
          title: "Selectors",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Cascading and Inheritance",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Custom properties: Variables",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Pseudo Classes",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Pseudo Elements",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Values and Units",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Fonts",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Text",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Box Model",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Color",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Images",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Background & Borders",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Positioned Layout",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Media Queries",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Flexible Box Layout",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Grid Layout Module",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Multi-column Layout",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Lists & Counters",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Counter Styles",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Transforms",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Transitions",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Animations",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Filter Effects",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Conditional Rules",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "CSS Syntax Algorithms",
          slug: "convert-a-theme-to-html-css",
          status: "not started",
          cryptedImageName: "e4a2d47e87fb89ac3cc261ff14992aa0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
      ],
    },
    {
      JavaScript: [
        {
          title: "JavaScript Fundamentals",
          slug: "javascript-fundamentals",
          status: "not started",
          cryptedImageName: "e8e7346de7b57b465a5ad4ac3428f074",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "JavaScript Fundamentals (Workshop)",
          slug: "javascript-fundamentals-workshop",
          status: "not started",
          cryptedImageName: "696ecca0a60380571e4e71787909abb3",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "JavaScript Prototypes",
          slug: "javascript-prototypes",
          status: "not started",
          cryptedImageName: "ea4a303b6ff25976d8da9810e455c3a1",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "JavaScript Prototypes (Workshop)",
          slug: "javascript-prototypes-workshop",
          status: "not started",
          cryptedImageName: "95aefc976922d8cb3594cda5787b7d0e",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "JavaScript DOM",
          slug: "javascript-dom",
          status: "not started",
          cryptedImageName: "fd0157d220a011048eee52e1810f6bd0",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "JavaScript DOM (Workshop)",
          slug: "javascript-dom-workshop",
          status: "not started",
          cryptedImageName: "b2561d6006e5bd00d599cf79127566c4",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "JavaScript ECMAScript 6",
          slug: "javascript-ecmascript-6",
          status: "not started",
          cryptedImageName: "c4439c353caab7c4d334e6da02e130e5",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "JavaScript ECMAScript 6 (Workshop)",
          slug: "javascript-ecmascript-6-workshop",
          status: "not started",
          cryptedImageName: "39c6497dc358fad36e0c54b88d789f47",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
      ],
    },
  ],
  Tools: [
    {
      "Dev Tools": [
        {
          title: "NPM (Node Package Manager)",
          slug: "npm-node-package-manager",
          status: "not started",
          cryptedImageName: "7f0d1ebc3198b576d2adef9b8c89d23f",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Git Explained",
          slug: "git-explained",
          status: "not started",
          cryptedImageName: "15f9c81fcb0a01f596a72eb2671a810e",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
      ],
    },
  ],
  Frameworks: [
    { "HTML / CSS": [] },
    {
      JavaScript: [
        {
          title: "React Fundamentals",
          slug: "reactjs-fundamentals",
          status: "not started",
          cryptedImageName: "95c9e24e6f92a971e9e8a42970c9605e",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "ReactJS Hooks",
          slug: "reactjs-hooks",
          status: "not started",
          cryptedImageName: "33665ab0cdab935c7d5ac1fd3d5db3f2",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "Build a React Application (Workshop)",
          slug: "build-a-react-application-workshop",
          status: "not started",
          cryptedImageName: "b3167bcd8c29c5bdbc1a845c5116947b",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
      ],
    },
  ],
  Interviews: [
    {
      "HTML / CSS": [
        {
          title: "HTML/CSS Interview Questions",
          slug: "html-css-interview-questions",
          status: "not started",
          cryptedImageName: "bd8451fccf2fc6fafc8aa240f1ed3078",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
      ],
    },
    {
      JavaScript: [
        {
          title: "JavaScript Interview Questions",
          slug: "javascript-interview-questions",
          status: "not started",
          cryptedImageName: "64d57c5b56272f6f40ae9a70962a16d5",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
        {
          title: "ReactJS Interview Questions",
          slug: "reactjs-interview-questions",
          status: "not started",
          cryptedImageName: "64d57c5b56272f6f40ae9a70962a1xd5",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
      ],
    },
  ],
  "Soft Skills": [
    {
      Teamwork: [
        {
          title: "Contribute to a Project on Github",
          slug: "contribute-to-a-project-on-github",
          status: "not started",
          cryptedImageName: "c20d02f8a27d3f21658f5a404f7cb71f",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
      ],
    },
    {
      "Online Presence": [
        {
          title: "Build your Portfolio",
          slug: "build-your-portfolio",
          status: "not started",
          cryptedImageName: "9657e79cdfe22b9ee08ed97f2463b53b",
          youtubePlayListLink: "",
          targetedDate: "",
          progression: "",
          lessons: "",
        },
      ],
    },
  ],
};

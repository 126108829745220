import React from "react";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

// Components
import PathCourse from "./path-course";

/**
 * Style
 */
const StyledPathSubCategory = styled.div`
  &.Path__section__subCategory {
    border: 1px solid ${colors.light.light300};
    margin-top: -1px;

    .Path__subCategory__heading {
      width: 100%;
      padding: 10px 0 9px 10px;
      color: #505050;
      background-color: ${colors.light.light200};
      font-size: 15px;
      font-weight: bold;
      user-select: none;
    }

    .Path__subCategory__coursesList {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      padding: 20px 10px 0 10px;

      flex-direction: row;
      padding-top: 15px;
    }
  }

  .Path__step--active {
    a {
      background: ${colors.light.light150};
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 4px ${colors.light.light200};
    }
  }

  .Path__step--inactive {
    a {
      opacity: 0.3;
    }
  }
`;

/**
 * Component
 */
const PathSubCategory = ({
  courses,
  categoryTitle,
  subCategoryTitle,
}) => {
  return (
    <StyledPathSubCategory
      className="Path__section__subCategory"
      style={{
        display:
          courses[subCategoryTitle].length > 0 ? "block" : "none",
      }}
    >
      {courses[subCategoryTitle].length > 0 ? (
        <div className="Path__subCategory__heading">
          <h5>
            {categoryTitle === "Tools" ||
            categoryTitle === "Frameworks" ||
            categoryTitle === "Interviews" ||
            categoryTitle === "Soft Skills" ? (
              <img
                src="/images/inprogress-sub-sections/sub-section.png"
                alt=""
              />
            ) : (
              subCategoryTitle
            )}
          </h5>
        </div>
      ) : (
        ""
      )}

      {/* Courses List */}
      <div className="Path__subCategory__coursesList">
        {(() => {
          if (courses[subCategoryTitle]) {
            return courses[subCategoryTitle].map((course, index) => {
              return (
                <PathCourse
                  key={`course-${index}`}
                  status={course.status}
                  title={course.title}
                  slug={course.slug}
                  cryptedImageName={course.cryptedImageName}
                  //
                  youtubePlayListLink={course.youtubePlayListLink}
                  targetedDate={course.targetedDate}
                  progression={course.progression}
                  lessonsFinished={course.lessonsFinished}
                  //
                  width="25%"
                />
              );
            });
          }
        })()}
      </div>
    </StyledPathSubCategory>
  );
};

export default PathSubCategory;

import React from "react";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledInfos = styled.div`
  &.Hero__Infos {
    flex: 1;
    min-width: 440px;
    color: ${colors.light.white};
    font-weight: 400;

    h1,
    p {
      margin-bottom: 30px;
    }

    h1 {
      font-size: 2.2em;
      line-height: 1.4em;
      letter-spacing: 1.4px;
    }

    p {
      line-height: 1.9em;
      font-size: 1.1em;

      strong {
        background: ${colors.primary.primary800};
        padding: 0 10px;
        margin-right: 4px;
      }
    }

    .btn {
      display: inline-block;
    }
  }

  .Hero__Slogan {
    b,
    mark {
      text-transform: uppercase;
    }
    b {
      text-decoration: line-through;
    }
    mark {
      display: inline-block;
      margin-top: 10px;
      padding: 0 10px;

      background: ${colors.primary.primary800};
      color: ${colors.primary.primary200};
    }
  }
`;

/**
 * Component
 */
const Infos = () => {
  return (
    <StyledInfos className="Hero__Infos">
      {/*  */}
      <h1 className="Hero__Slogan">
        We all start from <b>Zero</b> <br />
        the Goal is to become a <mark>Hero</mark>
      </h1>

      {/*  */}
      <p>
        A new vision that will take you from zero knowledge to master
        a career in <strong>Web Development</strong>; our focus is on
        paths more than only disconnected courses; we will help you
        see the whole picture step by step by giving you guidance in a
        straightway.
      </p>
    </StyledInfos>
  );
};

export default Infos;

import React from "react";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

// Data
import courses from "./data";

// Components
import PathSection from "./path-section";

/**
 * Style
 */
const StyledPathCourses = styled.div`
  .PathCourses {
    margin-top: 20px;
    width: 100%;
    background: ${colors.light.white};
    border: 1px solid ${colors.light.light300};

    .Path__courses__heading {
      h3 {
        padding: 20px 15px;
        background: ${colors.primaryGradient.primaryGradient100};
        color: ${colors.dark.dark600};
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;

        b {
          display: block;
          margin-top: 5px;
          font-size: 1em;
          font-weight: 100;
          text-transform: lowercase;
          color: ${colors.light.light600};
        }
      }
    }
  }
`;

/**
 * Component
 */
const Courses = () => {
  return (
    <div className="Container">
      <div className="Section">
        {/* Title */}
        <header className="Section__Headline Text--center">
          <p className="Text--small Opacity--40 FontWeight--bold Text--uppercase">
            In Progress Path
          </p>
          <h2 className="Text--xlarge FontWeight--bold">
            Front-End Engineer
          </h2>
        </header>

        {/* Content */}
        <StyledPathCourses>
          <div className="PathCourses">
            {/* Heading */}
            <div className="Path__courses__heading">
              <h3>
                Courses
                <b>25 courses and 1038 lessons</b>
              </h3>
            </div>

            {/* Sections */}
            <div className="Path__courses__sections">
              {Object.entries(courses).map((category, index) => {
                const sectionTitle = category[0];
                const sectionContent = category[1];

                return (
                  <PathSection
                    key={`section-${index}`}
                    title={sectionTitle}
                    coursesBasicKnowledge={
                      sectionTitle === "Before You Start"
                        ? sectionContent
                        : {}
                    }
                    coursesLanguages={
                      sectionTitle === "Languages"
                        ? sectionContent
                        : {}
                    }
                    coursesFrameworks={
                      sectionTitle === "Frameworks"
                        ? sectionContent
                        : {}
                    }
                    coursesTools={
                      sectionTitle === "Tools" ? sectionContent : {}
                    }
                    coursesSoftSkills={
                      sectionTitle === "Soft Skills"
                        ? sectionContent
                        : {}
                    }
                    coursesInterviews={
                      sectionTitle === "Interviews"
                        ? sectionContent
                        : {}
                    }
                  />
                );
              })}
            </div>
          </div>
        </StyledPathCourses>
      </div>
    </div>
  );
};

export default Courses;

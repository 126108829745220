import React from "react";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

// Components
import Infos from "../hero/infos";
import VideoTeaser from "../hero/video-teaser";
import ArtWork from "../hero/art";

/**
 *
 */
const StyledHero = styled.section`
  &.Hero {
    position: relative;
    width: 100%;
    background: ${colors.primary.primary700};

    .Container {
      display: flex;
      align-items: center;
      padding: 50px 0 100px 0;
    }
  }

  /**
  * Responsive
  * */
  @media (max-width: 991px) {
    &.Hero {
      .Container {
        flex-direction: column-reverse;
        margin: 0;
      }

      .Hero__VideoTeaser {
        margin: 0;
        margin-top: -30px;
        width: 100%;

        .VideoTeaser__Wrapper {
          border: none;
          padding: 0;
        }
      }

      .Hero__Infos {
        margin: 30px 25px 0 25px;

        .Hero__Slogan {
          font-size: 1.5em;
          margin-bottom: 20px;
        }

        p {
          margin-bottom: 0;
        }
      }

      .Hero__VideoTeaser,
      .Hero__Infos {
        flex: 1;
        min-width: auto;
      }

      .Hero__Artwork {
        display: none;
      }
    }
  }
`;

/**
 * Component
 */
const Hero = () => {
  return (
    <StyledHero className="Hero">
      <div className="Container">
        <Infos />
        <ArtWork />
        {/* <VideoTeaser /> */}
      </div>
    </StyledHero>
  );
};

export default Hero;

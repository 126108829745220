import React from "react";

// Components
import { createGlobalStyle, css } from "styled-components";

// Utils
import colors from "../utils/colors";
import { latinFonts } from "../utils/fonts";

/**
 * Reset
 */
const reset = css`
  *,
  :after,
  :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  div pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  font,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  figure,
  header,
  nav,
  section,
  article,
  aside,
  footer,
  figcaption {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
  }

  header,
  nav,
  section,
  article,
  aside,
  footer,
  hgroup {
    display: block;
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  p {
    line-height: 1.8em;
  }

  input {
    &:focus {
      outline: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
    }
  }

  a {
    text-decoration: none;
    color: ${colors.primary.main};

    &:hover {
      text-decoration: none;
    }
  }
`;

/**
 *
 */
const html = css`
  html,
  body {
    font-family: ${latinFonts.body};
    font-weight: 400;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    -ms-overflow-style: scrollbar;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    font-family: sans-serif;
    line-height: 1.15;
  }

  body {
    background: ${colors.light.light150};
  }
`;

/**
 *
 */
const layout = css`
  /**
  *
  * Layout
  *
  */
  @media (min-width: 576px) {
    .Container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .Container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .Container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .Container {
      max-width: 1140px;
    }
  }

  .Container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    &.Container--centered {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
`;

/**
 *
 */
const text = css`
  /**
  *
  * Text
  *
  */
  .Text--uppercase {
    text-transform: uppercase;
  }

  .Text--xlarge {
    color: ${colors.primary.primary700};
    font-size: 36px;
    letter-spacing: -0.9px;
  }

  .Text--small {
    font-size: 14px;
  }

  .FontWeight--bold {
    font-weight: 700;
  }

  .Opacity--40 {
    opacity: 0.4;
  }

  .Text--center {
    text-align: center;
  }

  .Display--inline {
    display: inline-block;
  }

  .Display--block {
    display: block;
  }
`;

/**
 *
 */
const section = css`
  .Section {
    padding: 50px 0;

    .Section__Headline {
      margin-bottom: 30px;

      p {
        margin-bottom: 8px;
      }
    }
  }
`;

/**
 *
 */
const button = css`
  .Btn {
    border: none;
    border-radius: 100px;
    text-align: center;
    transition: all 0.3s ease 0s;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.Btn--large {
      min-width: 120px;
      min-height: 40px;
      line-height: 40px;
      padding: 0 40px;
      font-size: 18px;
      text-transform: initial;
    }

    &.Btn--medium {
      min-width: 80px;
      min-height: 35px;
      line-height: 35px;
      padding: 0 30px;
    }

    &.Btn--small {
      min-width: 80px;
      min-height: 25px;
      line-height: 25px;
      padding: 0 10px;
      font-size: 13px;
    }

    &.Btn--white {
      background: ${colors.light.white};
      color: ${colors.dark.black};

      &:hover {
        background-color: ${colors.primary.main};
        color: ${colors.light.white};
      }
    }

    &.Btn--black {
      background: ${colors.dark.black};
      color: ${colors.light.white};

      &:hover {
        background-color: ${colors.primary.main};
        color: ${colors.light.white};
      }
    }

    &.Btn--dark {
      background: ${colors.primary.primary700};
      color: ${colors.light.white};

      &:hover {
        background-color: ${colors.primary.main};
        color: ${colors.light.white};
      }
    }

    &.Btn--secondary {
      background: ${colors.secondary.main};
      color: ${colors.light.white};

      &:hover {
        background-color: ${colors.secondary.secondary700};
        color: ${colors.light.white};
      }
    }

    &.Btn--youtube {
      background: ${colors.youtube.main};
      color: ${colors.light.white};

      &:hover {
        background-color: ${colors.youtube.dark};
        color: ${colors.light.white};
      }
    }

    &.Btn--outline {
      border-style: solid;
      border-color: ${colors.light.white};
      border-width: 1px;
    }

    &.Btn--outline--white {
      border-color: ${colors.light.white};
      color: ${colors.light.white} !important;

      &:hover {
        background-color: ${colors.youtube.dark};
        border-color: ${colors.youtube.dark};
        color: ${colors.light.white} !important;
      }
    }

    &.Btn--outline--dark {
      border-color: ${colors.primary.primary700};
      color: ${colors.primary.primary700} !important;

      &:hover {
        background: ${colors.primary.primary700};
        color: ${colors.light.white} !important;
      }
    }

    &.Btn--outline--black {
      border-color: ${colors.dark.black};
      color: ${colors.dark.black} !important;

      &:hover {
        background: ${colors.primary.primary800};
        color: ${colors.light.white} !important;
      }
    }
  }
`;

/**
 * Global Style Parent
 */
export const GlobalStyle = createGlobalStyle`
  ${reset};
  ${html}
  ${section}
  ${text}
  ${layout}
  ${button}
`;

/**
 * Reset Component
 */
export const Reset = () => {
  return <GlobalStyle />;
};

import React from "react";
import ReactPlayer from "react-player/youtube";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledVideoTeaser = styled.div`
  &.Hero__VideoTeaser {
    position: relative;
    flex: 2;
    margin-left: 37px;

    background: ${colors.primary.primary350};
    padding: 10px, 5px;
    text-align: right;
  }

  .VideoTeaser__Wrapper {
    padding: 10px;

    border: 1px solid ${colors.primary.primary300};
    border-radius: 5px;
  }
`;

/**
 * Component
 */
const VideoTeaser = () => {
  return (
    <StyledVideoTeaser className="Hero__VideoTeaser">
      <div className="VideoTeaser__Wrapper">
        <ReactPlayer
          className="VideoTeaser__Player"
          url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
          width="100%"
        />
      </div>
    </StyledVideoTeaser>
  );
};

export default VideoTeaser;

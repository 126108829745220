export default {
  light: {
    white: "#fff",
    light600: "#999999",
    light500: "#6f8ba4",
    light400: "#cccccc",
    light300: "#dadada",
    light200: "#edeff0",
    light150: "#eee",
    light100: "#f5f5f5",
    lightGradient:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.84) 0%, rgba(246, 246, 246, 0.84) 47%, rgba(237, 237, 237, 0.84) 100%)",
  },

  dark: {
    black: "#000",
    dark700: "#383b47",
    dark600: "#4d4d4d",
    dark500: "#7d8087",
  },

  primary: {
    main: "#3d82f0",
    primary50: "#dfe3ff",
    primary100: "#a1adff",
    primary200: "#e0e4ff",
    primary300: "#6a7ae9",
    primary350: "#4b59b7",
    primary700: "#333e8a",
    primary800: "#262e67",
    primary850: "#20285a",
  },

  primaryGradient: {
    primaryGradient100:
      "linear-gradient(to bottom, rgba(246, 250, 255, 0.9) 0%,rgba(218, 236, 246, 0.9) 100%)",
    primaryGradient200:
      "linear-gradient(to bottom,rgba(255, 255, 255, 0.12) 0%,rgba(67, 79, 163, 0.24) 100%)",
  },

  secondary: {
    main: "#3aa23f",
    secondary100: "#d7ffd8",
    secondary200: "#6aea6f",
    secondary700: "#2d7930",
  },

  status: {
    inPreparation: {
      main: "#8021a8",
      inPreparation200: "#f5ddff",
      inPreparation700: "#5d167b",
    },
  },

  youtube: {
    main: "#c4302b",
    dark: "#831f1b",
  },
};

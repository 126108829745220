import React from "react";
import styled from "styled-components";

// Style
import { Reset } from "../styles/global/reset";

// Componetns
import Header from "../components/header/header";
import Footer from "../components/footer/footer";

/**
 * Style
 */
const StyledMain = styled.main`
  /**
  * Responsive
  */
  @media (max-width: 991px) {
    padding-top: 50px;
  }
`;

/**
 * Component
 */
const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <StyledMain>{children}</StyledMain>
      <Footer />

      {/* Global Style */}
      <Reset />
    </>
  );
};

export default Layout;

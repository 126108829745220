import React from "react";

/**
 * Global Components
 */
import Layout from "../components/layout";
import SEO from "../components/seo";

/**
 * Sections Components
 */
import Hero from "../components/sections/hero/hero";
import JoinNow from "../components/sections/join-now/join-now";
import Courses from "../components/sections/courses/courses";

/**
 * Component
 */
export default function Home() {
  return (
    <Layout>
      <SEO title="Learn Web Dev Effectively | Ziro To Hero by Arbaoui Mehdi" />

      {/* Sections */}
      <Hero />

      {/* Join Now */}
      <JoinNow />

      {/*  */}
      <Courses />
    </Layout>
  );
}

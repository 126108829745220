import React from "react";
import styled from "styled-components";
import Countdown from "react-countdown";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledPathCourse = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  position: relative;
  margin-bottom: 20px;
  padding: 0 10px;
  width: 25%;

  > img {
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${colors.light.light150};
  }

  > div:hover {
    &:hover {
      border: 1px solid ${colors.light.light600};
    }
  }

  /* In Progress */
  &.PathCourse--inProgress {
    > div {
      > div:not(.PathCourse__CTA):not(.PathCourse__countdown) {
        filter: grayscale(1) opacity(0.6);
      }
    }
  }

  /* Completed */
  &.PathCourse--completed {
    > div,
    h3,
    .PathCourse__countdown {
      color: ${colors.primary.primary700} !important;
    }

    > div {
      background: ${colors.primary.primary200};
    }

    .Btn {
      background: ${colors.primary.primary700};
    }
  }

  /* In Preparation */
  &.PathCourse--inPreparation {
    > div,
    h3,
    .PathCourse__countdown {
      color: ${colors.status.inPreparation.main} !important;
    }

    h3 {
      position: relative;
      top: 3px;
    }

    > div {
      justify-content: flex-start;
      background: ${colors.status.inPreparation.inPreparation200};
    }

    img {
      filter: hue-rotate(45deg);
    }

    .PathCourse__countdown {
      height: 50%;
      align-items: center;
    }

    .Btn {
      background: ${colors.status.inPreparation.inPreparation700};
    }
  }

  > div {
    width: 100%;
    height: 303px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    padding: 10px;

    border-radius: 5px;
    background: ${colors.light.white};
    border: 1px solid #ccc;

    text-align: center;
    color: ${colors.dark.dark600};

    img {
      width: 78px;
    }
  }

  .PathCourse__StatsWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .PathCourse__stats {
    span {
      display: block;
      text-align: left;
      font-size: 13px;

      &:last-child {
        font-weight: bold;
      }
    }

    ~ .PathCourse__stats {
      left: inherit;
      right: 15px;

      span {
        text-align: right;
      }
    }
  }

  .PathCourse__infos {
    h3 {
      margin-bottom: 10px;
      color: ${colors.dark.dark600};
      font-size: 13px;
      line-height: 1.5em;
    }
  }

  .PathCourse__step-number {
    position: absolute;
    top: -7px;
    right: -12px;
    z-index: 1;

    height: 20px;
    width: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    background: ${colors.secondary.main};
    font-size: 12px;
    color: ${colors.secondary.secondary100};
  }

  .PathCourse__countdown {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
    padding: 10px 0;
    /* color: ${colors.secondary.secondary700}; */

    border-top: 1px dashed ${colors.dark.dark500};
    border-bottom: 1px dashed ${colors.dark.dark500};
    font-size: 0.8em;

    &.PathCourse__countdown--finished {
      color: ${colors.primary.primary850};
    }

    > div {
      > div {
        margin-right: 5px;
        &:nth-child(2) {
          font-weight: bold;
        }
      }
    }
  }

  .PathCourse__CTA {
    width: 100%;
    .Btn {
      width: 100%;
    }
  }

  /**
  * Responsive
  */
  @media (max-width: 991px) {
    width: 100%;
  }
`;

/**
 * Component
 */
const PathCourse = ({
  cryptedImageName,
  status,
  title,
  slug,
  width,
  //
  youtubePlayListLink,
  targetedDate,
  progression,
  lessonsFinished,
}) => {
  return (
    <>
      {(() => {
        if (status === "in progress" || status === "finished" || status === "in preparation") {
          return (
            <StyledPathCourse
              className={`PathCourse ${
                status === "in progress"
                  ? "PathCourse--inProgress"
                  : status === "finished"
                  ? "PathCourse--completed"
                  : status === "in preparation"
                  ? "PathCourse--inPreparation"
                  : "PathCourse--inProgress"
              }`}
              width={width}
            >
              <div>
                {/* Infos */}
                <div className="PathCourse__infos">
                  <h3>{title}</h3>
                  <img src={`/images/courses/${slug}.` + "svg"} alt={title} />
                </div>

                {/*  */}
                <div className="PathCourse__StatsWrapper">
                  <div className="PathCourse__stats">
                    <span>{status === "in preparation" ? "Phase" : "lessons"}</span>
                    <span>
                      <b>{lessonsFinished}</b>
                    </span>
                  </div>

                  <div className="PathCourse__stats">
                    <span>Progression</span>
                    <span>{progression}</span>
                  </div>
                </div>

                {/* Countdown */}
                {status === "in progress" ? (
                  <Countdown
                    date={targetedDate}
                    intervalDelay={0}
                    precision={3}
                    renderer={(props) => {
                      return (
                        <div className="PathCourse__countdown">
                          <div>
                            <div>{props.days}</div>
                            <div>days</div>
                          </div>
                          <div>
                            <div>{props.hours}</div>
                            <div>hours</div>
                          </div>
                          <div>
                            <div>{props.minutes}</div>
                            <div>minutes</div>
                          </div>
                          <div>
                            <div>{props.seconds}</div>
                            <div>seconds</div>
                          </div>
                        </div>
                      );
                    }}
                  />
                ) : status === "in preparation" ? (
                  <div className={`PathCourse__countdown`}>
                    <div>
                      <div>In Preparation</div>
                      <div>Course</div>
                    </div>
                  </div>
                ) : (
                  <div className={`PathCourse__countdown`}>
                    <div>
                      <div>Finished</div>
                      <div>Course</div>
                    </div>
                  </div>
                )}

                {/* CTA */}
                {status === "in progress" || status === "finished" ? (
                  <div className="PathCourse__CTA">
                    <a
                      className={`Btn Btn--secondary Btn--medium Display--inline`}
                      href={youtubePlayListLink}
                      target="_blank"
                      rel="noreferrer"
                      rel="noopener"
                    >
                      Watch
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </StyledPathCourse>
          );
        }

        return (
          <StyledPathCourse className="PathCourse">
            <img src={`/images/inprogress-courses/${cryptedImageName}.png`} alt="" />
          </StyledPathCourse>
        );
      })()}
    </>
  );
};

export default PathCourse;

import React from "react";
import styled from "styled-components";

import PathSubCategory from "./path-sub-category";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledPathSection = styled.div`
  margin-top: 20px;
  padding: 0 15px 30px 15px;
  border-bottom: 1px dashed ${colors.light.light300};

  .Path__section__category {
    display: flex;
    flex-direction: column;

    h4 {
      align-self: center;
      padding: 9px 15px;

      border-radius: 5px 5px 0 0;
      background: ${colors.light.light200};
      border-bottom: 1px solid ${colors.light.light200};
      font-size: 14px;
    }
  }
`;

/**
 * Component
 */
const PathSection = ({
  title,
  coursesBasicKnowledge,
  coursesLanguages,
  coursesFrameworks,
  coursesTools,
  coursesSoftSkills,
  coursesInterviews,
}) => {
  return (
    <StyledPathSection className="Path__courses__section">
      {/* Sub Categories */}
      {(() => {
        if (coursesLanguages && coursesFrameworks) {
          const courses = {
            "Before You Start": coursesBasicKnowledge,
            Languages: coursesLanguages,
            Frameworks: coursesFrameworks,
            Tools: coursesTools,
            "Soft Skills": coursesSoftSkills,
            Interviews: coursesInterviews,
          };

          return (
            <>
              {/* Category Name */}
              <div className="Path__section__category">
                <h4>
                  <span>{title}</span>
                </h4>
              </div>

              {courses[title].map((course, index) => {
                return (
                  <PathSubCategory
                    key={`subcategory-${index}`}
                    courses={course}
                    categoryTitle={title}
                    subCategoryTitle={Object.keys(course)[0]}
                  />
                );
              })}
            </>
          );
        }
      })()}
    </StyledPathSection>
  );
};

export default PathSection;

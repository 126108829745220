import React from "react";
import styled from "styled-components";

// Utils
import colors from "../../../styles/utils/colors";

/**
 * Style
 */
const StyledJoinNow = styled.section`
  position: relative;
  margin-top: -40px;
  z-index: 1;
  font-size: 1.2em;

  .Container {
    background-color: ${colors.light.white};
    border-radius: 7px;
    border: 1px solid #c9c9c9;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);

    h2 {
      text-align: center;
      margin-top: 30px;
      padding-bottom: 30px;
      border-bottom: 1px dashed ${colors.light.light400};
      font-size: 1.3em;
    }
  }

  form {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 50px 0;

    .Form__field {
      &:first-child,
      &:nth-child(2) {
        width: 30%;
      }
      margin-right: 15px;
    }

    label {
      display: block;
      margin-bottom: 15px;
      font-weight: bold;
    }

    input {
      display: block;
      width: 100%;
      padding: 9px 19px;

      background-color: ${colors.light.light200};
      border: 2px solid ${colors.light.light400};

      font-size: 0.9em;
      font-weight: 400;

      border-radius: 3px;
      transition: 1s;

      &:focus {
        background-color: ${colors.light.white};
      }
    }
  }
  /**
  * Responsive
  */
  @media (max-width: 991px) {
    width: 90%;
    margin: -60px auto auto auto;

    form {
      flex-direction: column;
      flex-wrap: wrap;
      padding: 25px 0 10px 0;

      .Form__field {
        width: 100% !important;
        margin-bottom: 15px;

        input,
        label {
          font-size: 0.8em;
        }

        button {
          margin-top: 10px;
          width: 100%;
        }
      }
    }

    .Container {
      h2 {
        font-size: 1.1em;
        padding-bottom: 20px;
      }
    }
  }
`;

/**
 * Component
 */
const JoinNow = () => {
  return (
    <StyledJoinNow className="JoinNow">
      <div className="Container">
        <h2>Get Notified When It 100% Completed</h2>
        <form
          action="https://app.convertkit.com/forms/1634085/subscriptions"
          method="post"
        >
          {/* Name */}
          <div className="Form__field">
            <label htmlFor="full_name">Your Name</label>
            <input
              type="text"
              aria-label="Your Name"
              name="fields[name]"
              placeholder="Name"
            />
          </div>

          {/* Email */}
          <div className="Form__field">
            <label htmlFor="email_address">Your email address</label>
            <input
              type="email"
              aria-label="Your email address"
              name="email_address"
              id="email_address"
              placeholder="Email address"
              required
            ></input>
          </div>

          {/* Submit */}
          <div className="Form__field">
            <button
              type="submit"
              data-element="submit"
              className="Btn Btn--secondary Btn--large Display--inline"
            >
              Join Now
            </button>
          </div>
        </form>
      </div>
    </StyledJoinNow>
  );
};

export default JoinNow;

import React from "react";
import { Helmet } from "react-helmet";

/**
 *
 *  SEO Component
 *
 */
function SEO({ description, meta, title }) {
  //
  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${title}`}
      link={[
        {
          rel: "stylesheet",
          type: "text/css",
          href:
            "https://fonts.googleapis.com/css2?family=Nunito&display=swap",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
};

export default SEO;

import React from "react";
import styled from "styled-components";

/**
 * Style
 */
const StyledArtWork = styled.div`
  flex: 1;
  margin-bottom: -90px;
  text-align: right;

  img {
    filter: grayscale(1);
  }
`;

/**
 * Component
 */
const ArtWork = () => {
  return (
    <StyledArtWork className="Hero__Artwork">
      <img src="/images/heros/arbaouimehdi.png" alt="" />
    </StyledArtWork>
  );
};

export default ArtWork;
